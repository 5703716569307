<template>
  <PageWithLayout>
    <Search
      :tabData="viewModel.tabData"
      :searchDataList.sync="searchData.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="searchData.searchParams"
      :dataList="searchData.dataList"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <span class="txt_right">
          성공 : <span class="tc_green">{{searchData.optionData.success_count}}건</span> / 실패 : <span class="tc_red">{{searchData.optionData.fail_count}}건</span>
        </span>
      </template>
    </Board>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import PointHistoryListViewModel from '@/views/service/point/viewModel/PointHistoryListViewModel'

export default {
name: 'PointHistoryList',
components:{
  PageWithLayout,
  Search,
  Board,
},
data(){
  return{
    viewModel: new PointHistoryListViewModel(),
  }
},
computed:{
    searchData(){
      return this.viewModel.searchData[this.viewModel.tabData.value];
    },
}
}
</script>

<style scoped>
</style>