
import TransactionModel from '@/views/service/point/model/TransactionModel';

import { convertDateFormat } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class PointHistoryListViewModel {
  constructor() {
    this.model = new TransactionModel();
    this.isModify = false;
    this.tabData = {
      dataList: [
        { id: 'SHEET_NAME', text:'구글 시트 이름으로 조회' },
        { id: 'TRANSACTION_ID', text:'조회한 거래 ID로 조회' },
      ],
      option:{
        isCount: false
      },
      value:'SHEET_NAME'
    }
    this.searchData = {
      SHEET_NAME:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'구글 시트 이름',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        optionData:{
          fail_count: 0,
          success_count: 0,
          transaction_id: '',
        },
        responseDate:'',
      },
      TRANSACTION_ID:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'조회한 거래 ID',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        optionData:{
          fail_count: 0,
          success_count: 0,
          transaction_id: '',
        },
        responseDate:'',
      },
    }
    this.boardData = {
      title:'포인트 지급 내역 조회',
      drawDataList: [
        {
          title:'가족대표<br>이름',
          width:'70px',
          value:'parents_user_name',
          isAlignLeft: true,
        },
        {
          title:'가족대표 전화번호',
          width:'118px',
          value:'parents_user_phone_number',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'지급할<br>자녀 이름',
          width:'68px',
          value:'child_user_name',
          isAlignLeft: true,
        },
        {
          title:'자녀 전화번호',
          width:'118px',
          value:'child_user_phone_number',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'지급할<br>포인트',
          width:'64px',
          value:'amount',
          filter: { name:'convertNumberToComma', value:false, value2:'-', },
          isAlignLeft: true,
        },
        {
          title:'자녀 uid',
          width:'200px',
          value:'child_firfin_user_uid',
          isAlignLeft: true,
        },
        {
          title:'포인트 이름',
          width:'150px',
          value:'transaction_name',
          isAlignLeft: true,
        },
        {
          title:'알림대상',
          width:'80px',
          value:'notification_target',
          isAlignLeft: true,
        },
        {
          title:'상태',
          width:'90px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'point_transaction_status'
          },
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'point_transaction_status',
              prev:'tc'
            },
          },
          isAlignLeft: true,
        },
        {
          title:'지급일시',
          width:'160px',
          value:'confirm_at',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true,
          isAlignLeft: true,
        },
        {
          title:'지급자',
          width:'150px',
          value:'confirm_by',
          isMobileTitle:true,
          isAlignLeft: true,
        },
        {
          title:'로드일시',
          width:'160px',
          value:'load_at',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true,
          isAlignLeft: true,
        },
        {
          title:'로드실패사유',
          width:'300px',
          value:'fail_reason',
          isAlignLeft: true,
          class:{
            name: 'tc_red'
          },
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        rowIdValue: 'uid'
      },
    };
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'ASC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }


  onClickLoad(){
    this.postPointLoad()
  }

  onSearch(){
    const value = this.searchData[this.tabData.value].searchDataList[0].value;
    switch(this.tabData.value){
      case 'SHEET_NAME' : { 
        this.getDataBySheetName(value)
        break
      }
      case 'TRANSACTION_ID' : { 
        this.getDataByTransactionId(value)
        break
      }
    }
  }

  getDataBySheetName(value){
    const path = apiPath.POINT_TRANSACTION_SHEETNAME;
    const data = {
      body:{
        sheet_name: value
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,data).then(
    (success) => {
      this.searchData[this.tabData.value].searched = true;
      const resultData = success.data;
      const {fail_count, success_count, transaction_id, transaction_list} = resultData.body;
      this.searchData[this.tabData.value].dataList = transaction_list;
      this.searchData[this.tabData.value].optionData = {
        fail_count: fail_count,
        success_count: success_count,
        transaction_id: transaction_id,
      };
      // this.searchData[this.tabData.value].paginationData.totalCount = resultData.total;
      // this.searchData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
      this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.searchData[this.tabData.value].hasData = resultData.total > 0;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getDataByTransactionId(value){
    const path = apiPath.POINT_TRANSACTION_ID.format(value);
    GET_REQUEST(path).then(
    (success) => {
      this.searchData[this.tabData.value].searched = true;
      const resultData = success.data;
      const {fail_count, success_count, transaction_id, transaction_list} = resultData.body;
      this.searchData[this.tabData.value].dataList = transaction_list;
      this.searchData[this.tabData.value].optionData = {
        fail_count: fail_count,
        success_count: success_count,
        transaction_id: transaction_id,
      };
      // this.searchData[this.tabData.value].paginationData.totalCount = resultData.total;
      // this.searchData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
      this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.searchData[this.tabData.value].hasData = resultData.total > 0;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}